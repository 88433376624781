.trainers{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    color: white;
    flex-direction: column;
    text-transform: uppercase;
}
.left-t>:nth-child(1){
    color: orange;
    font-weight: bold;
    font-size: 2rem;
}
.left-t>:nth-child(2){
    font-size: 3rem;
    font-weight: bold;
    padding: 2rem 0rem;
}
.left-t>:nth-child(3){
    text-transform: capitalize;
    padding: 0.1rem 0rem;
    
}
.left-t>:nth-child(4){
    font-size: 1rem;
    font-style: italic;
    color: orange;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    position: absolute;
width: 17rem;
height: 20rem;
object-fit: cover;
right: 8.5rem;
top: 1rem;

}
.right-t>:nth-child(1){
    
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
background-color: transparent;
right: 9rem;
top: 0.9rem;
}
.right-t>:nth-child(2){
    
    position: absolute;
    width: 17rem;
    height: 18rem;
    
    background:  var(--planCard);

right: 7rem;
top: 5rem;
}
.arrow{
    display: flex;
    position: absolute;
    gap: 1rem;
    bottom: 1rem;
    left: 3rem;
}
.arrow>img{
    width: 1.5rem;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    
    .left-t{
        top: 0;
        margin-left: 3rem;
     
    }
    .left-t>:nth-child(1){
        top: 0;
        
        margin-left: 0rem;
        font-size: 2rem;
     
    }
    .trainers{
       flex-direction: column;
       
    }
    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        justify-content: center;
    }
    .right-t>div{
        position: relative;
        
    }
    .right-t>:nth-child(1){
        left: 0%;
        top: 0.8rem;
        background-color: orange;
    }
    .right-t>:nth-child(2){
display: none;
    }
    .right-t>img{
     position: relative;
     align-self: center;
     top: 0;
    border: 2px solid orange;
     right: 0;
    
    }
    .left-t>:nth-child(2){
        font-size: 1.2rem;
        font-weight: bold;
        padding: 1rem 0rem;
       left: 0;
    }
    .left-t>:nth-child(3){
        font-size: 1rem;
        font-weight: bold;
        padding: 0rem 0rem;
       left: 0;
       margin-top: 0;
       
    }
    .left-t>:nth-child(4){
        margin-top: 0rem;
        top: 0%;
        padding: 0%;
letter-spacing: normal;
        font-size: small;
    }
    .arrow{
        left: 0%;top: 0.3rem;
    }
    
}