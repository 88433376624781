.join{
display: flex;
padding: 0 2 rem;
gap: 10rem;
}
.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
    left: 2rem;
    top: 2rem;
    letter-spacing: 4px;
}
.left-j>:nth-child(1){
    position: absolute;
    width: 12rem;
    border: 2px solid orange;
   
}
.right-j{
    display: flex;
    align-items: flex-end;
    justify-content: center;
  
}
.email{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email>input{
    border: none;
    background-color: transparent;
    outline: none;
    color: rgb(255, 255, 255);
    font-size: 1rem;
}
::placeholder{
    color: rgb(255, 255, 255);
}
.jbtn{
    color: white;
    background-color: orange;
 padding: 0.6rem 1rem;
    border: none;
}
.jbtn:hover{
    color: rgb(255, 255, 255);
    background-color: rgb(255, 145, 0);
}
@media screen and (max-width: 768px){
 .left-j{
    top: 0%;
    margin-left: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }   
 .left-j>:nth-child(3), .left-j>:nth-child(2){
    font-size: xx-large;
    justify-content: center;
    align-items: center;
    margin-left: 0rem;
 }
 .join{
    flex-direction: column;
    gap: 1rem;
 
 }
 .right-j{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0;
    padding: 0;
  
}
.email{
    display: flex;
    gap: 0rem;
    background-color: gray;
    padding: 1rem 2rem;
    width: 14rem;
}
.jbtn{
    color: white;
    background-color: orange;
 padding: 0.2rem 1.1rem;
 margin-right: 2rem;
 letter-spacing: normal;
    border: none;
}
.email>input{
    
    font-size: 0.8rem;
}
}