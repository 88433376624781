.reasons{
    display: flex;
    padding: 0 2rem;
    /* margin: 1rem 1rem; */
    grid-template:2rem ;
}
.images{
    width: 294px;
    height: 250px;
    padding: 0.5rem 0.2rem;
   
}
.reasons{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.whyus{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
}
.gym-img{
    border:2px solid orange;
}
@media screen and (max-width: 768px){
    .images{
        width: 97.6%;
        height:16rem;
        
    }
    
}